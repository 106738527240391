/*
 * File: Contact.module.css
 * Project: autofi-front
 * File Created: Wednesday, 1st March 2023 04:27:02
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 9th March 2023 08:56:05
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.contactForm {
    display: grid;
    flex-direction: column;
    align-items: center;
}

.lineBlock {
    display: flex;
    width: 80%;
    justify-content: center;
    margin: auto;
    gap: 2rem;
}

.inputBlock {
    float: left;
    width: 33%;
}

.input {
    display: block;
    border-radius: 3px;
}

.title {
    text-decoration: underline var(--theme-color);
    margin: auto;
    width: fit-content;
}

.inputSize {
    width: 100%;
    min-height: 2.5rem;
    padding: 0.1rem 0.5rem;
    border: 0;
    border-bottom: solid 1px var(--theme-color);
}
.inputSize:focus-visible{
    outline: 1px solid var(--theme-color);
}

.inputSelect {
    color: var(--bs-body-color);
    background-color: #e9e9ed;
}

.mandatory {
    color: red;
}

.wrongInput:focus,
.wrongInput {
    border: 0.2rem solid red;
}

@media ( width < 800px ){
    .title{
        margin-top: 1rem;
    }
    .inputBlock{
        width: 80%;
    }
    .lineBlock{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1rem auto;
    }
}
@media ( width < 600px ){
    .inputBlock{
        width: 100%;
    }
    .lineBlock{
        width: 100%;
    }
}
