/*
 * File: inputSelect.css
 * Project: autobuyclient
 * File Created: Saturday, 16th September 2023 09:58:59
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Saturday, 16th September 2023 10:01:12
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.inputSelect > .singleValue {
    overflow: initial !important;
}
